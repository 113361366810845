import React from 'react'
import styles from './index.module.less'

export default () => {
    return (
        <div className={styles.global_empty_body}>
            <div />
            <p>暂无内容</p>
        </div>
    )
}
